import React, { Component } from 'react';
import { connect } from 'react-redux';
import Img from 'gatsby-image';
import { navigate } from 'gatsby';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import Layout from '../../components/Layout';
import Strip from '../../components/Strip';
import Translate from '../../components/Translate';
import Button from '../../components/Button';
import ProductStrip from '../../components/ProductStrip';
import NewsStrip from '../../components/NewsStrip';

import styles from './style.module.css';

class HomeComponent extends Component {
	goToNews(url) {
		navigate(url);
	}

	goToProducts() {
		let slugsServices = [
			{
				slug: 'prodotti',
				polylang_current_lang_code: 'it',
			},
			{
				slug: 'products',
				polylang_current_lang_code: 'en',
			},
		];

		navigate(this.slugger(slugsServices));
	}

	goToServices() {
		let slugsServices = [
			{
				slug: 'servizi',
				polylang_current_lang_code: 'it',
			},
			{
				slug: 'services',
				polylang_current_lang_code: 'en',
			},
		];

		navigate(this.slugger(slugsServices));
	}

	slugger(slugs) {
		return `/${this.props.state.lang}/${slugs
			.map((item) => {
				let slug = '';
				if (item.polylang_current_lang_code === this.props.state.lang) {
					slug = item.slug;
				}
				return slug;
			})
			.join('')}`;
	}

	render() {
		const news = this.props.data.news.edges || [];
		const fields = this.props.data.page.acf;

		/**
		 * Cover strip
		 **/

		const coverStrip = {
			title: fields.cover_title,
			image: fields.cover_image.localFile.childImageSharp.original,
			image_thumb: fields.cover_image.localFile.childImageSharp.resolutions,
		};

		let coverStripTag = (
			<Strip
				className={styles.coverStrip}
				classNameChildren={styles.coverStripChildren}
				backgroundImageSrcUrl={coverStrip.image.src}
				backgroundImageThumbSrcUrl={coverStrip.image_thumb.src}>
				<h1 className={styles.coverTitle}>{coverStrip.title}</h1>
			</Strip>
		);

		/**
		 * Certification strip
		 **/

		let certificationStripTag = '';
		let certificationStrip = {};

		if (fields.certification && fields.certification.length > 0) {
			certificationStrip.title = fields.certification[0].post_title || null;
			if (fields.certification[0].acf) {
				if (fields.certification[0].acf.summary) {
					certificationStrip.description = fields.certification[0].acf.summary || null;
				}
				if (
					fields.certification[0].acf.icon &&
					fields.certification[0].acf.icon.localFile &&
					fields.certification[0].acf.icon.localFile.childImageSharp
				) {
					certificationStrip.image = fields.certification[0].acf.icon.localFile.childImageSharp.fluid || null;
					certificationStrip.image_thumb =
						fields.certification[0].acf.icon.localFile.childImageSharp.resolutions || null;
				}
			}
		}

		if (Object.keys(certificationStrip).length > 0 && !!certificationStrip.title && !!certificationStrip.description) {
			let imgTag = certificationStrip.image ? (
				<Img className={styles.certificationLogo} fluid={certificationStrip.image} key={certificationStrip.image.src} />
			) : null;
			certificationStripTag = (
				<Strip classNameChildren={styles.certificationStrip}>
					{imgTag ? imgTag : <span />}
					<section className={styles.certificationDescription}>
						<h2>{certificationStrip.title}</h2>
						<span dangerouslySetInnerHTML={{ __html: certificationStrip.description }} />
					</section>
				</Strip>
			);
		}

		/**
		 * Services strip
		 **/

		let services = fields.services && fields.services.length > 0 ? fields.services : [];

		let servicesStripTag = (
			<Strip className={styles.serviceStrip}>
				<h2>
					<Translate>homePage.servicesTitle</Translate>
				</h2>
				<ul className={styles.serviceList}>
					{services.map((s) => {
						let service = s.acf
							? {
									title: s.post_title,
									description: s.acf.summary,
									image: s.acf.icon.localFile.childImageSharp.fluid,
							  }
							: {};
						let imgTag = service.image ? (
							<Img className={styles.serviceLogo} fluid={service.image} key={service.image.src} />
						) : null;
						return (
							<li key={service.title}>
								{imgTag ? imgTag : <span />}
								<h3>{service.title}</h3>
								<span className={styles.serviceDescription} dangerouslySetInnerHTML={{ __html: service.description }} />
							</li>
						);
					}, this)}
				</ul>
				<Button
					onClickCallback={() => {
						this.goToServices();
					}}
					className={styles.servicesButton + ' ' + styles.whiteButton}>
					<Translate>homePage.goToServices</Translate>
				</Button>
			</Strip>
		);

		/**
		 * Products strip
		 **/

		const productStrip = {
			title: fields.product.product_title,
			image: fields.product.product_image.localFile.childImageSharp.fluid,
			image_thumb: fields.product.product_image.localFile.childImageSharp.resolutions,
			product_info_1: fields.product.product_info_1,
			product_info_2: fields.product.product_info_2,
			product_info_3: fields.product.product_info_3,
			product_info_4: fields.product.product_info_4,
			product_related: fields.product.pruduct_related,
		};

		/**
		 * Business area strip
		 **/

		const businessStrip = {
			title: fields.business_area.business_area_title,
			items: fields.business_area.business_area_items,
		};

		let businessStripTag = (
			<Strip className={styles.businessStrip}>
				<h2>
					<Translate>homePage.businessStripTitle</Translate>
				</h2>
				<p dangerouslySetInnerHTML={{ __html: businessStrip.title }} />
				<ul className={styles.businessStripList}>
					{businessStrip.items.map((b) => {
						return (
							<li key={b.business_area_item_title} className={styles.businessStripBox}>
								{b.business_area_item_title}
							</li>
						);
					}, this)}
				</ul>
			</Strip>
		);

		/**
		 * Inspiration strip
		 **/

		const inspirationStrip = {
			title: fields.inspiration.inspiration_title,
			image: fields.inspiration.inspiration_image.localFile.childImageSharp.original,
			image_thumb: fields.inspiration.inspiration_image.localFile.childImageSharp.resolutions,
			value: 'linear-gradient(90deg,rgba(56, 116, 214, 1),rgba(56, 116, 214, 0.85) 200%)',
		};

		let inspirationStripTag = (
			<Strip
				className={styles.inspirationStrip}
				classNameChildren={styles.inspirationStripChildren}
				backgroundImageSrcUrl={inspirationStrip.image.src}
				backgroundImageOption={inspirationStrip.value}
				backgroundImageThumbSrcUrl={inspirationStrip.image_thumb.src}>
				<h2 className={styles.inspirationTitle}>{inspirationStrip.title}</h2>
			</Strip>
		);

		/**
		 * News strip
		 **/
		const newsStrip = {
			news: news,
			baseUrlNews: `/${this.props.state.lang}/news`,
			title: 'homePage.newsStripTitle',
			buttonNews: 'homePage.goToNews',
		};

		/**
		 * Generate the page
		 */

		return (
			<Layout metadata={this.props.data.page.yoast}>
				{coverStripTag}
				{certificationStripTag}
				{servicesStripTag}
				<ProductStrip onClickCallback={this.goToProducts.bind(this)} productStrip={productStrip} />
				{businessStripTag}
				{inspirationStripTag}
				{news.length > 0 ? (
					<NewsStrip hideButton={false} onClickCallback={this.goToNews} newsStrip={newsStrip} />
				) : (
					<div />
				)}
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HomeComponent);
